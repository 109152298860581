<template>
<div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                    <form @submit.prevent="onSearch()" class="input-group no-border">
                         <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                     </form>
                    </div>
                    <div class="col-md-4">
                    <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>
                    </div>
                    <div class="col-md-4">
                        <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.product.create'}" class="btn btn-primary btn-md btn-add" > <i class="fas fa-pencil-alt"> </i>
                            اضافة منتج</router-link>
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة المنتجات</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                         الاسم بالعربي
                        </th>
                        <th>
                         الاسم بالانجليزي
                        </th>
                        <th>
                          الكمية
                        </th>
                        <th>
                          السعر
                        </th>
                        <th>
                          التصنيف
                        </th>
                        <th>
                          offline_id
                        </th>
                        
                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(product,index) in ProductsList.data" :key="index">
                          <td v-if="ProductsList.meta.current_page===1">{{(index+1)}}</td>
                          <td v-else>{{((index+1)+((ProductsList.meta.current_page-1)* ProductsList.meta.per_page))}}</td>
                          <td>
                            {{product.name.ar}}
                          </td>
                          <td>
                            {{product.name.en}}
                          </td>
                          <td>
                            {{product.quantity}}
                          </td>
                          <td>
                            {{product.selling_price}}
                          </td>
                          <td>
                            <span v-for="(category,inner_index) in product.CategoryProducts" :key="inner_index">
                                {{category.category.name.ar}}
                            </span>
                          </td>
                          <td>
                            {{product.offline_id}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                          
                            <router-link v-if="manageService('manage_categories')" :to="{name: 'admin.dashboard.product.categories.list', params: {product_uuid: product.uuid}}" class="btn btn-success btn-sm" > 
                             التصنيفات</router-link>
                            <router-link  :to="{name: 'admin.dashboard.product.attachments.list', params: {uuid: product.uuid}}" class="btn btn-success btn-sm" > 
                             المرفقات</router-link>
                            <router-link  :to="{name: 'admin.dashboard.product.view', params: {uuid: product.uuid}}" class="btn btn-success btn-sm" > 
                             عرض</router-link>
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.product.edit', params: {uuid: product.uuid}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(product)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="ProductsList.meta.last_page > 1" :pagination="ProductsList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../components/Pagination.vue'
import PerPage from '../../../components/PerPage'
import ConfirmAlert from '../../../components/ConfirmAlert'
import SuccessAlert from '../../../components/SuccessAlert'
export default {
  data(){
   return{
   filter:{
        name:'',
        page:1,
        paginate:1,
        limit:'100',
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedProduct:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({
        ProductsList:state=>state.admin.products.ProductsList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchProductsList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
                this.filter.page=1;
                store.dispatch('admin/fetchProductsList',this.filter).then(()=>{
                  store.commit('admin/PleaseStopLoading');
                }).catch((error)=>{
                  store.commit('admin/PleaseStopLoading');
                });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(product){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedProduct=product;
        },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteProduct',this.SelectedProduct.uuid).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchProductsList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert
   },

  beforeRouteEnter(to,from,next){     
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchProductsList',{page:1,name:'',paginate:1,limit:100}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next();  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>